@import "../website-v2/_variables.less";

.header {
  &--left {
    .header__content {
      align-items: start;

      .header__subheading {
        text-align: left;
      }
    }

    .header__badge {
      padding-left: var(--space-0);
    }
  }

  &--center {
    margin: 0 auto;
    
    .header__heading,
    .header__subheading {
      text-align: center;
    }
  }

  &__layout {
    &--columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--space-16);

      @media screen and (max-width: @screen-md) {
        grid-template-columns: 1fr;
      }

      .header__content {
        gap: var(--space-32);
        padding: 0 var(--space-32);
      }

      .header__image {
        padding: 0 var(--space-32);
      }
    }

    &--narrow {
      max-width: 45rem;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-40);
  }

  &__badge {
    --badge-height: 2.5rem;
    --badge-color: var(--cyan-300);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--octo-font-size-small);
    letter-spacing: 0.1rem;
    width: fit-content;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    max-height: var(--badge-height);
    background: var(--text-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__heading {
    font-weight: 900;
    // Override global h1-6 styles
    margin: 0;
    color: var(--input-text);
    text-wrap: balance;

    &.h1 {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }

    &.h2 {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    &.h3 {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &.h4 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &.h5 {
      font-size: 1.25rem;
      line-height: 2rem;
    }

    &.h6 {
      font-size: 1rem;
      line-height: 1.75rem;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
    }

    strong {
      background: var(--text-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__subheading {
    font-size: var(--h4);
    line-height: 160%;
    font-weight: 400;
    color: var(--input-text);
    text-align: center;
    text-wrap: balance;
    display: flex;
    flex-direction: column;
    gap: var(--space-16);

    p {
      // Override global p styles
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      margin: 0;
    }
  }

  &__form {
    width: 100%;

    // Marketo overrides
    .mktoForm {
      width: 100% !important;

      .mktoField {
        width: 100% !important;
      }
    }
  }

  &__image {
    &--bottom {
      .layout--level-4 .stripe:first-child:has(&) {
        padding-bottom: var(--space-0);
      }
    }

    // Override image styles
    .image-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}